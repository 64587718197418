export const productNameData=[
"OrbitaFaceScan",
"SinuScopeX",
"NasoNeckSight",
"ThoracicGuard",
"LungLeader",
"SpineSightX",
"AbdoSight",
"RenalRay",
"PelviXpert",
"SkeletalScope",
"CerebralSight",
"CerebroScanCT",
"SellaPlain",
"ContrastCortex",
"SinuVisionCT",
"SinuScanAI",
"FacialFrameCT",
"MaxilloMorph",
"FacialFrameFinder",
"FacialFinesse",
"TemporalTune",
"TemporalHarmony",
"CerviScanCT",
"CerviContrastAI",
"Thoracisight",
"ThoraxTrend",
"PulmoPrecision",
"SpineSightCT",
"SpineContrastCT",
"AbdoPelviScan",
"ContrastCoreCT",
"KUBrightScan",
"UroVisionCT",
"ExtremiPlainCT",
"ExtremiContrastCT",
"SpineScreenMRI",
"BrainScanAI",
"BrainEnhanceMRI",
"SinuScopeMRI",
"SinuBrightMRI",
"FaceFocusAI",
"ContrastCrafter",
"TemporalPlainAI",
"TemporalContrastAI",
"CerviScanAI",
"ContrastNeckNavigator",
"ThoraxThriveAI",
"ThoraxContrastCompass",
"AbdoMappAI",
"AbdoContrastAI",
"PelviPathAI",
"PelviGlowAI",
"RenalRevealAI",
"KUBrightContrastAI",
"UroTrackAI",
"UroGlowAI",
"AbdoPelviScanAI",
"CoreScanAI",
"CardioMammaryAI",
"MSKMasterAI",
"MSKEnhanceAI",
"ScanScreenAI",
"SpineSpectAI",
"MRISpineContrastAI",
"VesselViewCT"
];