import React, { useEffect, useState } from "react";
import DragDrop from "../components/DragDrop";
import ProgressBar from "../components/ProgressBar";
import Navbar from "../components/Navbar";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface LoadingScreenProps {
  loadingWidth: number;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ loadingWidth }) => (
  <div className="bg-white mt-4 p-8 rounded border border-black flex flex-col items-center">
    <div className="w-full h-full">
      <ProgressBar upload_scan={{ isDone: false, loading: loadingWidth }} report={{ isDone: false }} />
    </div>
    <br/><br/>
    <p className="text-black font-bold text-lg mb-4">AI Processing</p>
    <div className="w-full bg-gray-200 rounded-full h-3 mb-4">
      <div className="bg-logo-blue h-full rounded-full" style={{ width: `${loadingWidth}%` }}></div>
    </div>
  </div>
);

const UploadScan: React.FC = () => {
  const navigate = useNavigate();
  const [loadingWidth, setLoadingWidth] = useState<number>(0);
  const report = useSelector((state: RootState) => state.OsteroCheck.report);
  

  useEffect(() => {
    if (report.loading) {
      const timer = setInterval(() => {
        if (loadingWidth < 96) {
          setLoadingWidth((prevWidth) => prevWidth + 4);
        }
      }, 500);

      return () => clearInterval(timer);
    }
  }, [report.loading, loadingWidth]);

  return (
    <div className="h-screen w-screen bg-white">
      <div className="flex justify-center">
        <div className="w-full max-w-screen-lg p-8">
          <div className="h-1/12">
            <Navbar />
          </div>
          {report.loading ? (
            <LoadingScreen loadingWidth={loadingWidth} />
          ) : (
            <>
              <button
                className="flex text-white bg-logo-blue mt-4 gap-2 items-center rounded-lg px-4 py-2"
                onClick={() => navigate("/list")}
              >
                <img
                  className="max-w-full overflow-hidden h-4 w-3 max-h-full"
                  alt=""
                  src="/img/back.png"
                />
                <div>Back</div>
              </button>
              <div className="bg-white mt-4 p-8 rounded border border-black">
                <div className="h-1/5 text-center flex flex-col gap-4">
                  <ProgressBar
                    upload_scan={{ isDone: false, loading: loadingWidth }}
                    report={{ isDone: false }}
                  />
              
                </div>
                <div className="">
              
                  <div className="h-full mt-8">
                    <DragDrop />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadScan;
