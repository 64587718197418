import React from "react";
import Navbar from "../components/Navbar";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
interface ProfileProps {}

const Profile = (props: ProfileProps) => {
  const profile = useSelector((state: RootState) => state.login.user);
  return (
    <div className="flex justify-center h-screen w-screen py-4 px-8 sm:px-4 sm:py-2">
      <div className="h-full w-full">
        <div className="h-1/12">
          <Navbar />
        </div>

        <div className=" bg-white pt-4  grid grid-cols-4 grid-rows-2  gap-x-6 sm:flex sm:flex-col">
          <div
            className="col-span-1 row-span-1 shadow-md rounded 
     rounded-2xl  p-8 overflow-x-auto overflow-y-auto grid justify-center items-center text-center
   bg-clean-white border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300 sm:mb-6">
            <img
              src="/img/profile.png"
              alt=""
              className=" max-h-70 max-w-80 mb-4 "
              style={{ borderRadius: "50%" }}
            />
            <p className="font-bold ">Dr.Aditi Raj</p>
            <p>surgeon</p>
          </div>

          <div
            className="col-span-3  row-span-2 shadow-md rounded
     rounded-2xl  p-14 px-32 bg-clean-white text-center
   border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300 overflow-y-auto sm:px-4">
            <div className="grid grid-cols-2  border-b pb-4 pt-8">
              <p className="text-left px-14 font-bold text-neutral-500 sm:px-0 sm:text-[14px]">
                {" "}
                Full Name
              </p>
              <p className="text-left px-14 font-bold sm:text-[14px] sm:px-0">
                {" "}
                {profile?.displayName ?? "Dr.Aditi Raj"}
              </p>
            </div>
            <div className="grid grid-cols-2  border-b pb-4 pt-8">
              <p className="text-left px-14 font-bold text-neutral-500 sm:px-0 sm:text-[14px]">
                {" "}
                Email ID{" "}
              </p>
              <p className="text-left px-14 font-bold sm:text-[14px] sm:px-0 sm:w-full">
                {" "}
                {profile?.email ?? "appications@5cnetwork.com"}
              </p>
            </div>
            <div className="grid grid-cols-2  border-b pb-4 pt-8">
              <p className="text-left px-14 font-bold text-neutral-500 sm:px-0 sm:text-[14px]">
                {" "}
                Phone Number
              </p>
              <p className="text-left px-14 font-bold sm:text-[14px] sm:px-0">
                {" "}
                {profile?.phoneNumber ?? "+91-9566915477"}
              </p>
            </div>
            <div className="grid grid-cols-2  border-b pb-4 pt-8">
              <p className="text-left px-14 font-bold text-neutral-500 sm:px-0 sm:text-[14px]">
                {" "}
                Hospital
              </p>
              <p className="text-left px-14 font-bold sm:text-[14px] sm:px-0"> Jeevan Hospital</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
