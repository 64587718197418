import React, { useState ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import {setFilename} from '../redux/OsteroCheckSlice'
import { Document, Page, pdfjs } from 'react-pdf';
import { requestAddPatient } from "../redux/OsteroCheckSlice";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface File {
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
}

const DragDrop: React.FC = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedPDF, setSelectedPDF] = useState<File | null>(null);
  const appDispatch = useAppDispatch();
  const productName = useSelector(
    (state: RootState) => state.OsteroCheck.productname || "OrbitaFaceScan"
  );
  const report = useSelector((state: RootState) => state.OsteroCheck.report);


  const handleImageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageFile: File = {
        id: file.name,
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file),
      };
      setSelectedImage(imageFile);
    }
  };

  const handlePDFInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const pdfFile: File = {
        id: file.name,
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file),
      };
      setSelectedPDF(pdfFile);
    }
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
  };

  const handlePDFDelete = () => {
    setSelectedPDF(null);
  };

  // const handleUpload = async () => {
  //   if (!selectedImage || !selectedPDF) {
  //     return;
  //   }
    
  //   appDispatch(requestAddPatient({
  //     imagename: selectedImage.name,
  //     productname: productName
  //   }));
  //   // Handle the upload logic here
    
  //   // After upload, navigate to the reports page
  //   if (!report.loading) {
  //     navigate("/reports");
  //   }
  // };

  const handleUpload = async () => {
    if (!selectedImage || !selectedPDF) {
      return;
    }
  
    try {
      await appDispatch(requestAddPatient({
        imagename: selectedImage.name,
        productname: productName
      }));

  
      
      if (!report.loading) {
        navigate("/reports");
      }
      
    } catch (error) {
      console.error("Error during upload:", error);
    }
  };
  const handleCancel = () => {
    setSelectedImage(null);
    setSelectedPDF(null);
  };
  
 

  return (
    <article>
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-bold my-4 mb-8">Upload Scan & Preliminary Report</h2>
        <div className="flex justify-center gap-x-40 w-full">
          {/* Image Upload */}
          <div className="flex flex-col justify-center items-center ml-3 gap-y-1">
            {selectedImage ? (
              <div className="relative w-64 h-64 border-2 border-gray-300 rounded flex flex-col items-center justify-center">
                <p className="absolute top-2 text-center text-sm">{selectedImage.name}</p>
                {/* <p className="text-center">{selectedImage.name}</p> */}
                <img src="/img/zip.png" className=" w-2/4 h-2/4"/>
              <button
                  onClick={handleImageDelete}
                  className="absolute bottom-2 right-2 bg-gray-200 text-white rounded-full p-1"
                >
                  <img src="/img/trash.png" alt="Delete" className="w-6 h-6" />
                </button>
                <p className="absolute bottom-2 text-center text-sm">{(selectedImage.size / 1024).toFixed(2)} KB</p>
              </div>
            ) : (
              <label htmlFor="imageInput" className="btn-upload-image flex flex-col items-center">
                <img src="/img/imgdrop.png" alt="Upload Image" className="mb-2" />
                <p className="bg-logo-blue text-white hover:opacity-90 font-bold px-4 py-2 rounded cursor-pointer">
                  Upload Scan
                </p>
                <input
                  type="file"
                  accept="image/*,.zip"
                  onChange={handleImageInputChange}
                  className="hidden"
                  id="imageInput"
                />
              </label>
            )}
          </div>
          {/* PDF Upload */}
          <div className="flex flex-col justify-center items-center mr-3 gap-y-1">
            {selectedPDF ? (
              <div className="relative w-64 h-64 border-2 border-gray-300 rounded flex flex-col items-center justify-center overflow-hidden">
              {/* <p className="absolute top-2 text-center text-sm">{selectedPDF.name}</p> */}
              {/* PDF Preview */}
              <div className="pdf-preview-container">
                <iframe src={selectedPDF.url} title="report-pdf"></iframe>
              </div>
              <button
                onClick={handlePDFDelete}
                className="absolute bottom-2 right-2 bg-gray-200 text-white rounded-full p-1"
              >
                <img src="/img/trash.png" alt="Delete" className="w-6 h-6" />
              </button>
              <p className="absolute bottom-2 text-center text-sm">{(selectedPDF.size / 1024).toFixed(2)} KB</p>
            </div>
            ) : (
              <label htmlFor="pdfInput" className="btn-upload-pdf flex flex-col items-center">
                <img src="/img/pdfUpload.png" alt="Upload PDF" className="mb-2" />
                <p className="bg-logo-blue text-white hover:opacity-90 font-bold px-4 py-2 rounded cursor-pointer">
                  Upload Report
                </p>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handlePDFInputChange}
                  className="hidden"
                  id="pdfInput"
                />
              </label>
            )}
          </div>
        </div>
        <br /><br />
        {selectedImage && selectedPDF && (
          <div className="flex justify-center gap-8 my-2">
            <button
              className="btn-cancel bg-red-500 font-bold text-white px-4 py-2 rounded"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="btn-submit font-bold bg-blue-600 text-white px-4 py-2 rounded"
              onClick={handleUpload}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </article>
  );
};

export default DragDrop;
