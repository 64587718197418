import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Navbar from "../components/Navbar";
import PatientData from "../components/PatientData";
import ProgressBar from "../components/ProgressBar";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const type = searchParams.get("type");
  const patientData = useSelector(
    (state: RootState) => state.OsteroCheck.patientData
  );


  const imageLinks: string[] = React.useMemo(() => {
    if (!patientData.data.blob_urls) return [];
    if (Array.isArray(patientData.data.blob_urls)) return patientData.data.blob_urls;
    try {
      return JSON.parse(patientData.data.blob_urls);
    } catch {
      return [];
    }
  }, [patientData.data.blob_urls]);



  return (
    <div className="flex justify-center h-screen w-screen py-4 px-8 sm:px-4 sm:py-2">
      <div className="h-full w-full">
        <div className="h-1/12">
          <Navbar />
        </div>

        {patientData.loading ? (
          <div
            className=" bg-white pt-4  grid   gap-x-6 h-full justify-center items-center shadow-md rounded
     rounded-2xl  p-8 bg-clean-white
   border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300 ">
            <div
              className="text-logo-blue inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status">
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>{" "}
          </div>
        ) : (

          <div className=" bg-white ">
            <button
              className="flex text-white bg-logo-blue mt-3 mb-3  gap-2 items-center rounded-lg px-4 py-2"
              onClick={() => navigate("/list")}
            >
              <img
                className="max-w-full overflow-hidden h-4 w-3 max-h-full"
                alt=""
                src="/img/back.png"
              />
              <div>Back</div>
            </button>

            <div
              className="col-span-3  row-span-2 shadow-md rounded
     rounded-2xl  p-8 bg-clean-white 
   border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-black overflow-y-auto sm:p-4"
            >
              <div className="flex flex-col gap-4">

                {type !== "completed" && (
                  <ProgressBar

                    upload_scan={{ isDone: true, loading: 100 }}
                    report={{ isDone: true, color: "green" }}
                  />


                )}
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: patientData.data?.html_content as string }}
              />

              <br></br>
              <div className=" mt-16 ml-16">
                <h2 className="font-normal text-lg mb-16 border-b-[0.5px] border-[#c7c5c5] pb-2 w-[95%]">Key Images</h2>
                <div className="image-gallery flex justify-evenly">
                  {imageLinks?.map((url: string, index: number) => (
                    <img
                      key={index}
                      src={url}
                      alt={`Patient image ${index + 1}`}
                      className="gallery-image w-[20%]"
                    />
                  ))}
                </div>
              </div>

            </div>
          </div>
        )}


      </div>
    </div>
  );
};

export default Reports;
