import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { setProductName } from '../redux/OsteroCheckSlice';
import { useAppDispatch, RootState } from '../redux/store';
import { useSelector } from "react-redux";
import { productNameData } from '../product';


const DropdownMenu = () => {
  const dispatch = useAppDispatch();
  const productName = useSelector(
    (state: RootState) => state.OsteroCheck.productname || "OrbitaFaceScan"
  );

  const handleChange = (selectedOption: { value: string; label: string } | null) => {
    if (selectedOption) {
      dispatch(setProductName(selectedOption.value));
    }
  };


  return (
    <Select
      options={productNameData.map(option => ({ value: option, label: option }))}
      isSearchable
      placeholder="OrbitaFaceScan"
      onChange={handleChange}
      value={productName ? { value: productName, label: productName } : null}
    />
  );
};

export default DropdownMenu;
