import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
const baseUrl = "https://api.chitr.cubebase.ai/"

export type StudiesType = {
  order_id: string;
  patient_name: string;
  patient_age: number;
  patient_sex: string;
  date: string;
  referring_doctor: string;
};

export type PatientType = {
  name: string;
  age: number;
  sex: string;
  phone_no: string;
  ref_doc: string;
  history: string;
  order_id?: string;
  blob_urls?: string[];
  html_content?: string;
};

export type StudiesOutputType = {
  // message: string;
  data: StudiesType[];
};

const savedProductName = localStorage.getItem('productname') || "OrbitaFaceScan";

export const requestStudiesList = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>("requestStudiesList", async (_, thunkAPI,) => {
  try {
    const response = await fetch(
      `${baseUrl}/data`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data.data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const requestReport = createAsyncThunk<
  any,
  string,
  { rejectValue: string }
>("requestReport", async (resData, thunkAPI:any) => {
  try {
    console.log(resData);
    const response = await fetch(
      `${baseUrl}/get-info/${resData}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log("getinfo",data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const downloadReport = createAsyncThunk<
  { "report_link": string },
  string | undefined,
  { rejectValue: string }
>("downloadReport", async (resData, thunkAPI) => {
  try {
    console.log(resData);
    const response = await fetch(
      `${baseUrl}/get-info?order_id=${resData}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const requestAddPatient = createAsyncThunk<
  { imagename: string; productname: string; patient_details: any; html_content: string; blob_urls: string[] },
  { imagename: string; productname: string },
  { rejectValue: string }
>("requestAddPatient", async (data, thunkAPI) => {
  try {
    console.log("Payload being sent: ", data);
    const response = await fetch(
      `${baseUrl}/input-upload`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      return thunkAPI.rejectWithValue("Failed to upload product");
    }

    const resData = await response.json();
    console.log("Response from API: ", resData);

    return resData; // Ensure this contains the expected properties
  } catch (error) {
    console.error("Error uploading product: ", error);
    return thunkAPI.rejectWithValue("Failed to upload product");
  }
});

export const requestStudiesByProduct = createAsyncThunk<
  any,
  string,
  { rejectValue: string }
>("requestStudiesByProduct", async (productName, thunkAPI) => {
  try {
    const response = await fetch(
      `${baseUrl}/get-by-product/${productName}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data.data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list by product");
  }
});

interface OsteroCheckSliceState {
  patientList: {
    data: StudiesOutputType;
    loading: boolean;
    error: string | null;
  };
  patientListData: {
    data: StudiesOutputType;
    loading: boolean;
    error: string | null;
  };
  patientData: { data: PatientType; loading: boolean; error: string | null };
  report: { loading: boolean; error: string | null };
  downloadReport: { loading: boolean; error: string | null };
  filename: string | null;
  productname: string | null;
  html_content: string | null;
  blob_urls: string[] | null;
}

const initialState: OsteroCheckSliceState = {
  patientList: {
    data: { data: [] },
    loading: false,
    error: null
  },
  patientListData:{
    data:{data:[]},
    loading: false,
    error: null
  },
  patientData: {
    data: {
      name: "",
      age: 0,
      sex: "",
      phone_no: "",
      ref_doc: "",
      history: "",
      blob_urls: [],
      html_content: "",
    },
    loading: false,
    error: null
  },
  report: { loading: false, error: null },
  downloadReport: { loading: false, error: null },
  filename: null,
  productname: savedProductName,
  html_content: null,
  blob_urls: null
};

export const OsteroCheckSlice = createSlice({
  name: "osteroCheck",
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<PatientType>) => {
      state.patientData.data = { ...state.patientData.data, ...action.payload };
    },
    setFilename: (state, action: PayloadAction<string>) => {
      state.filename = action.payload;
    },
    setProductName: (state, action: PayloadAction<string>) => {
      state.productname = action.payload;
      localStorage.setItem('productname', action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(requestStudiesByProduct.pending, (state) => {
      state.patientListData.loading = true;
      state.patientListData.error = null;
    })
    .addCase(requestStudiesByProduct.fulfilled, (state, action) => {
      state.patientListData.loading = false;
      state.patientListData.data = action.payload;
    })
    .addCase(requestStudiesByProduct.rejected, (state, action) => {
      state.patientListData.loading = false;
      state.patientListData.error = action.error.message || "Something went wrong";
    })
      .addCase(requestStudiesList.pending, (state) => {
        state.patientList.loading = true;
        state.patientList.error = null;
      })
      .addCase(requestStudiesList.fulfilled, (state, action) => {
        state.patientList.loading = false;
        state.patientList.data = action.payload;
      })
      .addCase(requestStudiesList.rejected, (state, action) => {
        state.patientList.loading = false;
        state.patientList.error = action.error.message || "Something went wrong";
      })
      // =====================================================
      .addCase(requestReport.pending, (state) => {
        state.patientData.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestReport.fulfilled, (state, action) => {
        state.patientData.loading = false;
        state.patientData.data = {
          ...state.patientData.data,
          order_id: action.payload.data[0].order_id,
          name: action.payload.data[0].patient_name,
          sex: action.payload.data[0].patient_sex,
          age: action.payload.data[0].patient_age,
          blob_urls: action.payload.data[0].image_link,
          html_content: action.payload.data[0].report_content,
        };
      })
      .addCase(requestReport.rejected, (state, action) => {
        state.patientData.loading = false;
        state.patientData.error = action.error.message || "Something went wrong";
      })
      // =====================================================
      .addCase(requestAddPatient.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestAddPatient.fulfilled, (state, action) => {
        state.report.loading = false;
        state.filename = action.payload.imagename;
        state.productname = action.payload.productname;
        state.patientData.data.html_content = action.payload.html_content;
        state.patientData.data.blob_urls = action.payload.blob_urls;
        console.log("datainredux", action.payload.blob_urls);
      })
      .addCase(requestAddPatient.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error = action.error.message || "Something went wrong";
      })
      // =====================================================
      .addCase(downloadReport.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.report.loading = false;
        state.patientData.data.html_content = action.payload.report_link;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error = action.error.message || "Something went wrong";
      });
  }
});

export const { addPatient, setFilename, setProductName } = OsteroCheckSlice.actions;
export default OsteroCheckSlice.reducer;
