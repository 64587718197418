import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { RootState, useAppDispatch } from "../redux/store";
import { requestStudiesList, requestReport } from "../redux/OsteroCheckSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestStudiesByProduct } from "../redux/OsteroCheckSlice";
import { AiOutlinePlus, AiOutlineEye } from "react-icons/ai";
import Navbar from "../components/Navbar";
import DropdownMenu from "../components/dropDown";

const StudiesList = () => {
  const appDispatch = useAppDispatch();
  const productName = useSelector((state: RootState) => state.OsteroCheck.productname || "OrbitaFaceScan");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    appDispatch(requestStudiesByProduct(productName));
  }, [appDispatch, productName]);



  const studies = useSelector(
    (state: RootState) => state.OsteroCheck.patientListData || { data: { data: [] } }
  );
  const imageName = useSelector(
    (state: RootState) => state.OsteroCheck.filename
  );

  





  const navigate = useNavigate();

  const handleReportView = (order_id: string) => {
    appDispatch(requestReport(order_id));
    navigate({
      pathname: "/reports",
      search: "?type=completed"
    });
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = studies.data?.data?.filter((row) =>
  row.patient_name.toLowerCase().includes(searchTerm.toLowerCase())
) || [];


  console.log("tabledata", studies.data.data)


  const tableRow = (row: any) => {
    return (
      <div className="flex border-b mx-4 justify-center items-center sm:mx-1">
        <div className="p-2 w-1/4 text-center sm:p-1">{row["patient_name"]}</div>
        <div className="p-2 w-1/4 text-center sm:p-1">
        {row["patient_age"]} / {row["patient_sex"]} 
        </div>
        <div className="p-2 w-1/4 text-center sm:p-1">{row["date"]}</div>

        <div className="p-2 w-1/4 flex justify-center sm:p-1">
          <img
            className="object-contain cursor-pointer pl-1 h-9 w-9"
            alt=""
            src="/img/mail.png"
          />
          <img
            className=" object-contain cursor-pointer pr-1 pl-1"
            alt=""
            src="/img/whatsup.svg"
          />
        </div>
        <div className="p-2 w-1/4 flex items-center justify-center ">
          <button
            className="flex items-center bg-sky-100 group hover:bg-logo-blue text-logo-blue font-semibold hover:text-white py-1 px-2 border border-logo-blue hover:border-transparent rounded"
            onClick={() => handleReportView(row["order_id"])}
          >
            <AiOutlineEye className="w-4 h-4 mr-2 text-logo-blue group-hover:text-white sm:mr-0" />
            <span className="sm:hidden">View</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center w-screen py-4 px-8 h-screen sm:py-2 sm:px-2">
      <div className="h-full w-full ">
        <div className=" h-2/12 ">
          <Navbar />
        </div>

        <div className="h-1/12 bg-white pt-4 ">
          <div className="flex justify-between items-center py-4">
            <h1 className="font-bold text-xl h-full w-1/4"> <DropdownMenu /> </h1>
            <div className="flex">
              <div className="relative flex items-center border border-solid p-[0.3rem] mr-2 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>

                <input
                  className="peer h-full w-full outline-none text-sm text-gray-700"
                  type="search"
                  id="search"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>

              <button
                className="bg-logo-blue hover:bg-gray-200 group hover:text-black text-white font-bold  px-8  rounded inline-flex items-center sm:px-4"
                onClick={() => navigate("/upload-scan")}
              >
                <AiOutlinePlus className="w-4 h-4 mr-2 text-white group-hover:text-black sm:mr-0 " />
                <span> Upload Report </span>
              </button>
            </div>
          </div>
        </div>

        <div className="h-9/12 w-full flex-grow-2 justify-between items-center text-justify">
        <Table
          headers={[
            "PATIENT NAME",
            "AGE, GENDER",
            "DATE",
            "SHARE",
            "REPORT"
          ]}
          tableRow={tableRow}
          data={filteredData}
        />
        </div>
      </div>
    </div>
  );
};

export default StudiesList;